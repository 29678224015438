var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _vm.userHasPermission("apps_integrations_edit")
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "mr-4 sm:mb-0 mb-2",
                          on: { click: _vm.addPriceRule },
                        },
                        [_vm._v("Add")]
                      )
                    : _vm._e(),
                  _c("vs-input", {
                    staticClass:
                      "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                    attrs: { placeholder: "Search..." },
                    on: { input: _vm.updateSearchQuery },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.priceRulesData,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true,
                context: _vm.context,
              },
              on: {
                "selection-changed": _vm.onSelectionChanged,
                "grid-size-changed": _vm.onGridSizeChanged,
              },
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: 5 },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
        _c(
          "vs-prompt",
          {
            attrs: {
              title: "Delete PriceRule",
              "accept-text": "Add",
              active: _vm.showDeletePriceRulePopup,
            },
            on: {
              cancel: _vm.closeDeletePriceRuleDialog,
              close: _vm.closeDeletePriceRuleDialog,
              accept: _vm.deletePriceRule,
              "update:active": function ($event) {
                _vm.showDeletePriceRulePopup = $event
              },
            },
          },
          [
            _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Assign Replacement PriceRule to Users:"),
                  ]),
                  _c("v-select", {
                    attrs: { options: _vm.replacementPriceRuleOptions },
                    model: {
                      value: _vm.selectedReplacementPriceRule,
                      callback: function ($$v) {
                        _vm.selectedReplacementPriceRule = $$v
                      },
                      expression: "selectedReplacementPriceRule",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }